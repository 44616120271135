






import { Component, Vue } from "vue-property-decorator";
import WelfareBlindBox from "@/views/welfare/components/WelfareBlindBox.vue";
@Component({
  components: { WelfareBlindBox },
})
export default class SupplyStore extends Vue {

}
